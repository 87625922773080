// eslint-disable-next-line import/no-mutable-exports
let cognitoconfig = {};
if (process.env.NODE_ENV === 'production') {
  cognitoconfig = {
    aws_project_region: 'eu-central-1',
    aws_cognito_region: 'eu-cental-1',
    aws_user_pools_id: 'eu-central-1_QfTevKWEw',
    aws_user_pools_web_client_id: '5l902612gabk3s3kq6r4k9uajt',
  };
} else {
  cognitoconfig = {
    aws_project_region: 'eu-central-1',
    aws_cognito_region: 'eu-cental-1',
    aws_user_pools_id: 'eu-central-1_QfTevKWEw',
    aws_user_pools_web_client_id: '5l902612gabk3s3kq6r4k9uajt',
  };
}

export default cognitoconfig;
