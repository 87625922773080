<template>
  <div>
    <!-- <p>YESTURDAY: {{ getYesturdayDate(7) }}</p>
    <p>selectedRoom: {{ selectedRoom }}</p>
    <p>statsForSelectedRoom: {{ statsForSelectedRoom }}</p>
    <p>statsChart: {{ statsCharts }}</p> -->
    <v-tabs
      v-model="tab"
      align-with-title
    >
      <v-tabs-slider color="blue"></v-tabs-slider>

      <v-tab
        v-for="item in zones"
        :key="item._id"
      >
        <v-badge
          :content="item.capacity"
          :value="5"
          :inline="true"
          color="grey"
          overlap
        >
          {{ item.name }}
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item"
      >
        <v-card flat>
          <v-card-text v-text="text[tab]"></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <div>
      <apexcharts
        width="100%"
        height="400px"
        type="line"
        :options="options"
        :series="series"
        :key="refreshStats">
      </apexcharts>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'RoomCharts',
  components: {
    apexcharts: VueApexCharts,
  },
  created() {
    this.zones = this.getZones();
    this.allStats = this.getStats();
  },
  data() {
    return {
      tab: null,
      items: [],
      text: [
        'Info sur la salle 1',
        'Info sur la salle 2',
        'Info sur la salle 3',
        'Info sur la salle 4',
        'Info sur la salle 5',
      ],
      refreshStats: false,
      options: {
        chart: {
          id: 'vuechart-room-chart',
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: '#303f9e',
          width: 3,
          dashArray: 0,
        },
        xaxis: {
          tickAmount: 12,
          labels: {
            formatter(timestamp) {
              // const Y = new Date(timestamp).getFullYear();
              let D = new Date(timestamp).getDate();
              if (D < 10) { D = `0${D}`; }
              let M = new Date(timestamp).getMonth() + 1;
              if (M < 10) { M = `0${M}`; }
              let H = new Date(timestamp).getHours();
              if (H < 10) { H = `0${H}`; }
              return `${D}/${M} ${H}:00`;
            },
          },
        },
        yaxis: {
          labels: {
            formatter(val) {
              return val.toFixed(0);
            },
          },
        },
      },
      series: [{
        name: 'Nombre de personnes',
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      }],
      isAllStatsReady: false,
      isZonesReady: false,
      zones: [],
      allStats: [],
    };
  },
  computed: {
    selectedRoom() {
      return this.zones[this.tab];
    },
    statsForSelectedRoom() {
      if (this.isAllStatsReady && this.isZonesReady) {
        return this.allStats.filter((elm) => elm.zoneId === this.zones[this.tab]._id);
      }
      return [];
    },
    statsCharts() {
      const fullData = [];
      if (this.isAllStatsReady && this.isZonesReady && this.selectedRoom) {
        this.statsForSelectedRoom.forEach((elm) => {
          for (let i = 0; i < elm.npeopleHours.length; i += 1) {
            const dataTime = new Date(elm.ts).setHours(i);
            const v = Math.ceil(elm.npeopleHours[i]);
            fullData.push([dataTime, Math.round(v)]);
          }
        });
      }
      return fullData;
    },
  },
  watch: {
    statsCharts(nVal) {
      this.series[0].data = nVal;
      this.refreshStats = !this.refreshStats;
    },
  },
  methods: {
    async getZones() {
      const zones = await this.$apollo.query({
        query: gql`
          query {
          zones {
            _id
            name
            capacity
          }
        }`,
        variables: {
          query: {
          },
        },
        fetchPolicy: 'network-only',
      });
      this.zones = zones.data.zones;
      this.isZonesReady = true;
      return zones.data.zones;
    },
    async getStats() {
      const stats = await this.$apollo.query({
        query: gql`
          query statistics ($query: StatisticQueryInput) {
          statistics (query: $query) {
            occupancyHours
            npeopleHours
            zoneId
            ts
          }
        }`,
        variables: {
          query: {
            ts_lt: this.getYesturdayDate(0),
            ts_gt: this.getYesturdayDate(15),
          },
        },
        fetchPolicy: 'network-only',
      });
      this.allStats = stats.data.statistics;
      this.isAllStatsReady = true;
      return stats.data.statistics;
    },
    getYesturdayDate(daysBefore) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - daysBefore);

      const Y = yesterday.getFullYear();
      const M = String(yesterday.getMonth() + 1).padStart(2, '0');
      const D = String(yesterday.getDate()).padStart(2, '0');

      return `${Y}-${M}-${D}T00:00:00.000+00:00`;
    },
    readableDateFromStringDate(strDate) {
      const dateFormat = new Date(strDate);
      let d = dateFormat.getDate();
      if (d < 10) { d = `0${d}`; }
      let m = dateFormat.getMonth() + 1;
      if (m < 10) { m = `0${m}`; }
      const y = dateFormat.getFullYear();
      let h = dateFormat.getHours();
      if (h < 10) { h = `0${h}`; }
      let mn = dateFormat.getMinutes();
      if (mn < 10) { mn = `0${mn}`; }
      return `${d}/${m}/${y} ${h}:${mn}`;
    },
  },
};
</script>

<style scoped>
</style>
