<template>
  <div class="door-list">
    <h3 class="door-title">Selectionnez une porte à ouvrir</h3>
    <div v-for="door in doors" :key="door.openingId">
      <v-btn
        class="btn-100"
        color="success"
        @click="openDoor(door.openingId)">
        {{ door.name }} ({{ door.openingId }})
      </v-btn>
    </div>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      La porte {{ lastDoorOpened }} est ouverte!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import gql from 'graphql-tag';

export default {
  data() {
    return {
      doors: [],
      snackbar: false,
      timeout: 2000,
      lastDoorOpened: '',
    };
  },
  created() {
    this.getDoors();
  },
  methods: {
    async getDoors() {
      // console.log('SEND MAIL TO: ', this.companyId, this.title);
      const doors = await this.$apollo.query({
        query: gql`
          query openings ($query: OpeningQueryInput) {
          openings (query: $query) {
            openingId
            name
          }
        }`,
        fetchPolicy: 'network-only',
      });
      console.log('DOORS: ', doors.data.openings);
      this.doors = doors.data.openings;
      return doors.data.openings;
    },
    async openDoor(doorId) {
      console.log('Door id is: ', doorId);
      const doorToOpen = await this.$apollo.query({
        query: gql`
          query open($input: OpeningQueryInput) {
            forceOpenDoor(input: $input) {
              openingId
              name
            }
          }`,
        variables: {
          input: {
            openingId: doorId,
          },
        },
        fetchPolicy: 'network-only',
      });
      console.log('DOORS OPENNED: ', doorToOpen);
      const resId = doorToOpen.data.forceOpenDoor.openingId;
      this.lastDoorOpened = this.doors.find((door) => door.openingId === resId).name;
      this.snackbar = true;
      return doorToOpen;
    },
  },
};
</script>

<style scoped>
.door-title {
  text-align: center;
}

.door-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 12px;
}

.btn-100 {
  width: 100%;
  margin: 6px 0;
}
</style>
