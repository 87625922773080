<template>
  <div class="pa-12">
    <data-cards></data-cards>
    <div class="ghost-vertical-separator"></div>
    <room-charts></room-charts>
  </div>
</template>

<script>
import DataCards from '../components/DataCards.vue';
import RoomCharts from '../components/RoomCharts.vue';

export default {
  name: 'Home',
  components: {
    DataCards,
    RoomCharts,
  },
};
</script>

<style scoped>
  .ghost-vertical-separator {
    width: 100%;
    height: 12px;
    background-color: transparent;
    margin: 24px 0;
  }
</style>
