<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-show="isLogged"
      v-model="drawer"
      app
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Village
          </v-list-item-title>
          <v-list-item-subtitle>
            Version 1.0.2
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="ma-6">
        <v-btn
          dark
          color="indigo"
          style="width:100%;"
          @click="selectDoor = true"
        >
          Ouverture forcée
        </v-btn>
      </div>
    </v-navigation-drawer>

    <v-app-bar
      v-show="isLogged"
      app
      color="indigo darken-2"
      dark
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title>Village Admin</v-app-bar-title>

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn> -->

      <v-btn icon>
         <v-icon @click="logoutUser">mdi-logout</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>

    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-dialog
      v-model="selectDoor"
      max-width="300"
    >
      <v-card>
        <OpeningDoor />
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import OpeningDoor from './components/OpeningDoor.vue';

export default {
  components: {
    OpeningDoor,
  },
  data: () => ({
    drawer: null,
    items: [
      { title: 'Dashboard', icon: 'mdi-view-dashboard', to: '/' },
      { title: 'Historique des E/S', icon: 'mdi-image', to: '/historique' },
      { title: 'Écran d\'accueil', icon: 'mdi-tablet-android', to: '/acceuil' },
      { title: 'Badge', icon: 'mdi-card-account-details-outline', to: '/badges' },
      { title: 'Plages d\'accès', icon: 'mdi-door-closed-lock', to: '/access' },
    ],
    options: [
      { title: 'Click Me', icon: 'mdi-logout' },
    ],
    selectDoor: false,
  }),
  computed: {
    isLogged() {
      return this.$store.getters.authStatus;
    },
  },
  methods: {
    logoutUser() {
      localStorage.clear();
      this.$router.push('/');
      window.location.reload();
    },
  },
};
</script>

<style>
.submit-button {
  background-color: #aaffbc !important;
}

/* input {
  border-bottom: 1px solid lightgray !important;
} */
</style>
