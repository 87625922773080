<template>
  <div>
    <v-card
    >
      <div class="next-item">
        <div class="item-info">
          <v-card-text>
            {{ title }}
          </v-card-text>
          <v-card-text
            class="font-weight-bold value-size">
            {{ value[idx] }}  <span class="door-name">({{ info[idx] }})</span>
          </v-card-text>
        </div>
        <div
          class="next-btn"
          @click="nextItem"
        >
          &#9654;
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Present dans le batiment',
    },
    value: {
      type: Array,
      default: () => [0, 1, 2],
    },
    info: {
      type: Array,
      default: () => ['R1', 'R2', 'R3'],
    },
  },
  data() {
    return {
      idx: 0,
    };
  },
  methods: {
    nextItem() {
      if (this.idx + 1 >= this.value.length) {
        this.idx = 0;
      } else {
        this.idx += 1;
      }
    },
  },
};
</script>

<style scoped>
  .next-item {
    display: flex;
  }

  .next-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 6px;
  }

  .next-btn:hover {
    cursor: pointer;
  }

  .item-info {
    width: 100%;
  }

  .value-size {
    font-size: 24px;
    padding-top: 0;
  }

  .door-name {
    font-size: 12px;
    font-weight: 300;
  }
</style>
