import Vue from 'vue';
import Auth from '@aws-amplify/auth';
import VueRouter from 'vue-router';
import { AWSEventBus } from '@bag-era/cognito-authentication';
import getValidAccessToken from '../mongo/realm';
import Home from '../views/Home.vue';
import cognitoconfig from '../aws-config';
import store from '../store';

Vue.use(VueRouter);
Auth.configure(cognitoconfig);

const ifAuthenticated = (to, from, next) => {
  getValidAccessToken()
    .then((res) => {
      window.localStorage.setItem('apollo-token', res);
    });
  if (store.getters.authStatus) {
    next();
    return;
  }
  next('/login');
};

const loginMetaSettings = (to, from, next) => {
  if (store.getters.authStatus) {
    next('/');
    return;
  }
  next();
};

const routes = [
  {
    path: '/reset',
    name: 'reset',
    component: () => import('../views/ResetPasswordView.vue'),
    beforeEnter: loginMetaSettings,
  },
  {
    path: '/changepwd',
    name: 'changepwd',
    component: () => import('../views/ChangeTmpPasswordView.vue'),
    beforeEnter: loginMetaSettings,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    beforeEnter: loginMetaSettings,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/historique',
    name: 'Historique',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Historique.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/acceuil',
    name: 'HomePageEditor',
    component: () => import('../views/HomePageEditor.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/badges',
    name: 'Badges',
    component: () => import('../views/Badges.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/access',
    name: 'Access',
    component: () => import('../views/Access.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

AWSEventBus.$on('loggedIn', async (data) => {
  const user = {
    username: data.username,
    Session: data.Session,
    info: data.signInUserSession.idToken.payload,
  };
  // -- Save token in the local storage to allow apollo to manipulate header queries
  getValidAccessToken()
    .then((res) => {
      window.localStorage.setItem('apollo-token', res);
      store.dispatch('authSucceeded', user).then(() => router.push('/'));
    });
});

AWSEventBus.$on('resetPassword', async (data) => {
  router.push({ path: '/reset', query: { username: data.username } });
});

AWSEventBus.$on('pwdChanged', async () => {
  router.push({ path: '/' });
});

AWSEventBus.$on('changeTmpPassword', async (data) => {
  router.push({ path: '/changepwd', query: { username: data.username, password: data.password } });
});

export default router;
