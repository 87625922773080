import Vue from 'vue';
import {
  Login, ChangeTmpPassword, ResetPassword,
} from '@bag-era/cognito-authentication';
import Auth from '@aws-amplify/auth';
import VueI18n from 'vue-i18n';
import feather from 'vue-icon';
import VueApexCharts from 'vue-apexcharts';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import { createProvider } from './vue-apollo';

Vue.config.productionTip = false;
Vue.use(Auth);
Vue.use(Login, ChangeTmpPassword, ResetPassword);
Vue.use(VueI18n);
Vue.use(feather, 'v-icon');
Vue.use(VueApexCharts);
new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
