<template>
  <div class="grid-3">
    <!-- <p>zones: {{ zones }}</p>
    <p>allstats: {{ allStats }}</p>
    <p>percentInBatiment: {{ percentInBatiment }}</p> -->
    <!-- <p>stats: {{ stats }}</p> -->
    <!-- <p>USERS: {{ users }}</p> -->
    <data-card
      :title="stats.inBat.title"
      :value="inBat"
      :info="stats.inBat.info">
    </data-card>
    <div></div>
    <data-card
      :title="stats.totalBadge.title"
      :value="`${users.length}`"
      :info="stats.totalBadge.info">
    </data-card>
    <data-card
      :title="stats.occupancy.title"
      :value="`${percentInBatiment[0].toFixed(2)}% vs ${percentInBatiment[1].toFixed(2)}%`"
      :info="stats.occupancy.info">
    </data-card>
    <div></div>
    <DataCardMultiple
      :title="stats.accessMode.title"
      :value="getDoorsStatus.status"
      :info="getDoorsStatus.name">
    </DataCardMultiple>
    <data-card
      :title="stats.invit.title"
      :value="getInvitFor7Days"
      :info="getInvitFor15Days">
    </data-card>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import USERS from '../graphQL/Users.gql';
import DataCard from './DataCard.vue';
import DataCardMultiple from './DataCardMultiple.vue';

export default {
  components: {
    DataCard,
    DataCardMultiple,
  },
  data() {
    return {
      allStats: [],
      isAllStatsReady: false,
      zones: [],
      isZonesReady: false,
      inBat: 0,
      inBatLastWeek: 0,
      doorMode: [],
      invit7: 0,
      invit15: 0,
      stats: {
        inBat: {
          title: 'Présent dans le batiment',
          value: '__',
          info: '',
        },
        totalBadge: {
          title: 'Badges attribués',
          value: '41',
          info: '',
        },
        occupancy: {
          title: 'Taux d’occupation moyen (comparaison sur 7 jours glissants)',
          value: '34%',
          info: '',
        },
        accessMode: {
          title: 'Mode d\'accés',
          value: 'Badge',
          info: '',
        },
        invit: {
          title: 'Nombre d’invitation effectuées cette semaine',
          value: this.invit7,
          info: `${this.invit15}  semaine précèdente.)`,
        },
      },
    };
  },
  apollo: {
    users: USERS,
  },
  async created() {
    this.allStats = this.getStats();
    this.zones = this.getZones();
    this.getStatsOpenning();
    this.getInsideBat();
    this.getDoorMode();
    try {
      this.invit7 = await this.getInvitations(0, 7);
      this.invit15 = await this.getInvitations(8, 15);
    } catch (err) {
      console.log('error on getInvitation: ', err);
    }
  },
  computed: {
    percentInBatiment() {
      // -- ****1 is for the the last 7 days and *****2 is for the next 7 days
      let sumOccupationPeriod1 = 0;
      let sumOccupationPeriod2 = 0;
      if (this.isAllStatsReady && this.isZonesReady) {
        const halfIdx = Math.trunc(this.allStats.length / 2);
        // -- Get last 7 days data
        let allStatPeriod1 = JSON.parse(JSON.stringify(this.allStats)).reverse();
        allStatPeriod1 = allStatPeriod1.splice(0, halfIdx);
        // -- Get the next 7 days data
        let allStatPeriod2 = JSON.parse(JSON.stringify(this.allStats)).reverse();
        allStatPeriod2 = allStatPeriod2.splice(halfIdx);

        // -- Start the compute for each period
        let medianList1 = 0;
        let medianList2 = 0;
        this.zones.forEach((zone) => {
          // -- Compute period 1
          const allDataPerZone1 = allStatPeriod1.filter((s) => s.zoneId === zone._id);
          const mergeZoneData1 = allDataPerZone1.map((d) => d.occupancyDay).flat();
          const sum1 = mergeZoneData1.reduce((a, b) => a + b, 0);
          medianList1 += sum1;
          // -- Compute period 2
          const allDataPerZone2 = allStatPeriod2.filter((s) => s.zoneId === zone._id);
          const mergeZoneData2 = allDataPerZone2.map((d) => d.occupancyDay).flat();
          const sum2 = mergeZoneData2.reduce((a, b) => a + b, 0);
          medianList2 += sum2;
        });
        sumOccupationPeriod1 = medianList1;
        sumOccupationPeriod2 = medianList2;
      }
      return [sumOccupationPeriod1, sumOccupationPeriod2];
    },
    getInvitFor7Days() {
      return this.invit7;
    },
    getInvitFor15Days() {
      return `(${this.invit15}  semaine précèdente.)`;
    },
    getDoorsStatus() {
      const status = [];
      const name = [];
      this.doorMode.forEach((e) => {
        name.push(e.name);
        const dateNowInSec = new Date().getTime() / 1000;
        if (dateNowInSec > e.mode.endTs) {
          status.push('Badge');
        } else {
          status.push(e.mode.value);
        }
      });
      return {
        status,
        name,
      };
    },
  },
  methods: {
    async getInsideBat() {
      const insideBat = await this.$apollo.query({
        query: gql`
          query {
          statistics(query: { _id: "instantStats" } ) {
            _id
            nbPeopleInside
            inside
          }
        }`,
        fetchPolicy: 'network-only',
      });
      console.log('insideBat: ', insideBat);
      this.inBat = insideBat.data.statistics[0].nbPeopleInside;
      return insideBat.data.statistics[0].nbPeopleInside;
    },
    async getZones() {
      const zones = await this.$apollo.query({
        query: gql`
          query {
          zones {
            _id
            name
            capacity
          }
        }`,
        variables: {
          query: {
          },
        },
        fetchPolicy: 'network-only',
      });
      this.zones = zones.data.zones;
      this.isZonesReady = true;
      return zones.data.zones;
    },
    async getStatsOpenning() {
      const stats = await this.$apollo.query({
        query: gql`
          query {
          statistics {
            _id
            nInvitationsWeek
            nTotalInvitations
            nTotalOpenings
            nTotalBells
          }
        }`,
        variables: {
          query: {
          },
        },
        fetchPolicy: 'network-only',
      });
      console.log('MAIN STATS: ', stats);
    },
    async getStats() {
      const stats = await this.$apollo.query({
        query: gql`
          query statistics ($query: StatisticQueryInput) {
          statistics (query: $query) {
            occupancyHours
            occupancyDay
            zoneId
            ts
          }
        }`,
        variables: {
          query: {
            ts_lt: this.getYesturdayDate(1),
            ts_gt: this.getYesturdayDate(15),
          },
        },
        fetchPolicy: 'network-only',
      });
      this.allStats = stats.data.statistics;
      this.isAllStatsReady = true;
      return stats.data.statistics;
    },
    async getDoorMode() {
      console.log('fetch door mode');
      const doors = await this.$apollo.query({
        query: gql`
          query openings ($query: OpeningQueryInput) {
          openings (query: $query) {
            openingId
            name
            mode {
              value
              endTs
            }
          }
        }`,
        fetchPolicy: 'network-only',
      });
      console.log('doors ====> ', doors);
      this.doorMode = doors.data.openings.map((e) => {
        if (e.mode.endTs < new Date()) {
          e.mode.value = 'badge';
        }
        return e;
      });
      return doors.data.openings;
    },
    async getInvitations(d1, d2) {
      console.log('FETCH invitation gql');
      const invitation = await this.$apollo.query({
        query: gql`
          query historyEvents ($query: HistoryEventQueryInput) {
          historyEvents (query: $query) {
            _id
            logTs
          }
        }`,
        variables: {
          query: {
            event: 'invitation',
            logTs_lt: this.getYesturdayDate(d1),
            logTs_gt: this.getYesturdayDate(d2),
          },
        },
        fetchPolicy: 'network-only',
      });
      console.log('invitation gql: ', invitation);
      // this.allStats = invitation.data.statistics;
      // this.isAllStatsReady = true;
      return invitation.data.historyEvents.length;
    },
    getYesturdayDate(daysBefore) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - daysBefore);

      const Y = yesterday.getFullYear();
      const M = String(yesterday.getMonth() + 1).padStart(2, '0');
      const D = String(yesterday.getDate()).padStart(2, '0');

      return `${Y}-${M}-${D}T00:00:00.000+00:00`;
    },
  },
};
</script>

<style scoped>
.grid-3 {
  display: grid;
  grid-template-columns: 4fr 3fr 3fr;
  gap: 16px;
}
</style>
