<template>
  <div>
    <v-card
    >
      <v-card-text>
        {{ title }}
      </v-card-text>
      <v-card-text
        class="font-weight-bold value-size">
        {{ value }}<span> {{ info }}</span>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Present dans le batiment',
    },
    value: {
      type: [String, Number],
      default: '0 %',
    },
    info: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      msg: 'Hi there!',
    };
  },
};
</script>

<style scoped>
  .value-size {
    font-size: 24px;
    padding-top: 0;
  }
</style>
