import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({})],
  state: {
    user: null,
    status: false,
  },
  mutations: {
    authSucceeded: (state, user) => {
      state.status = true;
      state.user = user;
    },
  },
  actions: {
    authSucceeded: (context, user) => {
      context.commit('authSucceeded', user);
    },
  },
  getters: {
    authStatus: (state) => state.status,
    getUserInfo: (state) => state.user,
  },
});
